<template>
  <a-spin :spinning="loading">
    <div class="content">
      {{ content }}
    </div>
  </a-spin>
</template>

<script>
export default {
  name: 'PreviewTxt',
  props: {
    fileUrl: {
      type: String,
      require: true
    },
  },
  data() {
    return {
      loading: true,
      url: '',
      content: '',
    }
  },
  mounted() {
    this.loadTxtContent()
  },
  methods: {
    loadTxtContent() {
      fetch(this.fileUrl)
        .then(res => res.blob()) // Gets the response and returns it as a blob
        .then(blob => {
          this.readAsText(blob)
        });
    },

    readAsText(file) {
      let reader = new FileReader()
      reader.onload = (e) => {
        this.content = e.target.result
        this.loading = false
      }
      reader.readAsText(file)
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  white-space: pre-wrap;
  padding: 10px 30px;
}
</style>
